.b-image {
  display: block;
  margin: rem(30px) 0;
}

.b-image__wrapper {
  padding: rem(20px) rem(30px);
}

.b-image__image {
  overflow: hidden;
  border-bottom-right-radius: rem(6px);
  border-bottom-left-radius: rem(60px);
  border-top-left-radius: rem(6px);
  border-top-right-radius: rem(60px);
}
