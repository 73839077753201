.b-testimonials {
  display: block;
  margin-top: rem(70px);
  position: relative;
  padding: 0 rem(30px) rem(20px);
  overflow: hidden;
}

.b-testimonials__heading {
  font-size: rem(25px);
  font-weight: 500;
  color: $color-7;
  text-transform: uppercase;
  max-width: rem(285px);
  font-family: $font-sans-serif;

  @include bp(medium) {
    margin: 0;
  }
}

.b-testimonials__carousel-outer {
  margin-top: rem(50px);

  @include bp(740px) {
    margin-top: rem(-30px);
  }
}

.b-testimonials__carousel-item {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  padding: 0 2rem;

  &.is-prev {
    opacity: 0.3;
  }

  &.is-active,
  &.is-prev {
    @include bp(740px) {
      margin-top: rem(90px);
    }
  }
}

.b-testimonials__item-image {
  display: inline-block;
  overflow: hidden;
  margin-right: rem(12px);

  // shape
  border-top-right-radius: 65%;
  border-bottom-left-radius: 65%;
  border-top-left-radius: 35%;
  border-bottom-right-radius: 35%;
}

.b-testimonials__author-img {
  width: 100%;
  max-width: rem(73px);
  height: auto;
}

.b-testimonials__item-author {
  display: flex;
  align-items: center;
}

.b-testimonials__item-heading {
  margin: 0;
  font-size: rem(16px);
  font-weight: 600;
  color: $color-7;
}

.b-testimonials__item-job-description {
  color: $color-7;
}

.b-testimonials__item-content {
  margin-top: rem(24px);
}

.b-testimonials__carousel-inner {
  padding-bottom: rem(40px);
}

.b-testimonials__carousel-loop-fix {
  margin: 0 -2rem;
}
