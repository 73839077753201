// https://gist.github.com/MoOx/9137295
@mixin btnReset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  text-align: inherit;

  /* iOS fix */
  border-radius: 0;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
}
