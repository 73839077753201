/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: $color-bg-footer;
  padding: rem(90px) 0 rem(36px);
}

.c-footer__wrapper {
  margin: auto rem(30px);

  @include bp(large) {
    margin: auto rem(120px);
  }
}

.c-footer__content {
  @include bp(large) {
    display: flex;
  }
}

.c-footer__content-logo {
  @include bp-down(small) {
    text-align: center;
  }
}

.c-footer__content-logo-img {
  width: 100%;
  max-width: rem(258px);
}

.c-footer__content-links {
  text-align: center;

  @include bp(small) {
    display: flex;
  }

  @include bp(large) {
    margin-left: auto;
    width: 40%;
    text-align: left;
  }
}

.c-footer__copyright {
  margin-top: rem(48px);
  padding-top: rem(11px);
  display: block;
  border-top: 1px solid $color-very-light-grey;
  font-size: rem(14px);
  color: $color-5;
}

.c-footer__content-logo-text {
  display: flex;
  align-items: center;
  margin-top: rem(36px);
  font-size: rem(20px);
  color: $color-link;

  svg {
    width: rem(30px);
    height: rem(30px);
    margin-right: rem(8px);
  }
}
