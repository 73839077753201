/* stylelint-disable */

.c-calculator {
  border-radius: rem(16px);
  background-color: $color-white;
  margin-top: rem(60px);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  padding: rem(20px);

  @include bp(medium) {
    padding: rem(40px);
  }
}

.c-calculator__form {
  @include bp(medium) {
    display: flex;
  }
}

.c-calculator__inputs {
  position: relative;

  @include bp(medium) {
    width: 55%;
    padding-right: rem(39px);

    &::after {
      content: "";
      width: 1px;
      height: calc(100% + rem(80px));
      background: $color-6;
      display: block;
      top: rem(-40px);
      right: 0;
      position: absolute;
    }
  }
}

.c-calculator__results {
  @include bp(medium) {
    width: 45%;
    padding-left: rem(38px);
  }
}

.c-calculator__submit {
  display: flex;
  margin-top: rem(24px);

  @include bp(medium) {
    margin-top: rem(14px);
  }
}

.c-calculator__btn {
  margin-left: auto;
}

.c-calculator__price-box {
  display: none;

  &.show {
    display: block;
  }
}

.c-calculator__price-box-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.c-calculator__price-box__box {
  @include btnReset();
  display: inline-block;
  margin: 0 rem(4px);
  border-top-left-radius: rem(10px);
  border-top-right-radius: rem(40px);
  border-bottom-left-radius: rem(40px);
  border-bottom-right-radius: rem(10px);
  padding: rem(10px) rem(30px) rem(18px);
  margin-bottom: rem(8px);
  text-align: center;
  background: $color-white;
  border: 1px solid rgba($color-4, 0.2);

  &.clickable {
    cursor: pointer;
  }

  &.active {
    background: rgba($color-4, 0.2);
  }

  &.force-hide {
    display: none;
  }

  @include bp(small) {
    width: calc(50% - rem(8px));
  }
}

.c-calculator__price-box__text {
  font-size: rem(12px);
}

.c-calculator__price-box__price {
  font-size: rem(40px);
  font-weight: 600;
  color: $color-1;
  margin-bottom: rem(8px);
}

.c-calculator__price-box__price-time {
  color: $color-text;
  font-size: rem(12px);
}

.c-calculator__tier-benefits__list {
  @include bp(medium) {
    display: flex;
  }
  padding: 0;
  margin: 0;
  margin-top: rem(24px);
}

.c-calculator__tier-benefits__list-col {
  @include bp(medium) {
    width: 50%;
  }
}

.c-calculator__tier-benefits__list-item {
  font-size: rem(12px);
  list-style-type: none;
  display: flex;
  position: relative;
  padding-left: rem(18px);

  svg {
    position: absolute;
    width: rem(14px);
    height: rem(14px);
    left: 0;
    top: rem(2px);
  }
}

.c-calculator__additional {
  display: flex;
}

.c-calculator__additional-text {
  font-size: rem(12px);
  display: block;
  max-width: rem(300px);
  text-align: right;
  margin-left: auto;
  margin-top: rem(20px);
}