.o-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: rem(40px) auto;
}

.o-tiles__item {
  margin-top: rem(40px);

  @include bp(medium) {
    width: calc(50% - rem(24px));
  }
}
