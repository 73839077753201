.o-heading {
  font-weight: 700;
  line-height: 1.1;
  font-family: $font-serif;
  color: $color-2;
}

.o-heading__text-above {
  display: inline-block;
  margin-bottom: rem(8px);
  text-transform: uppercase;
  color: $color-8;
  font-size: rem(16px);
}
