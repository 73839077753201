.b-team-members {
  margin: rem(50px) 0;
  padding: rem(20px) rem(30px);
}

.b-team-members__heading {
  font-size: rem(25px);
  font-weight: 500;
  color: $color-7;
  text-transform: uppercase;
  max-width: rem(285px);
  font-family: $font-sans-serif;

  @include bp(medium) {
    margin: 0;
  }
}

.b-team-members__items {
  margin-top: rem(120px);
  display: block;
}

.b-team-members__item {
  margin: rem(80px) 0;

  @include bp(small) {
    display: flex;
    align-items: flex-start;
  }

  @include bp(medium) {
    width: 60%;

    &:nth-of-type(even) {
      margin: rem(-20px) 0 rem(-20px) auto;
    }
  }
}

.b-team-members__image {
  display: block;
  flex: 0 0 100%;
  width: 100%;
  max-width: rem(230px);
  overflow: hidden;
  border-radius: rem(16px);

  @include bp-down(small) {
    margin: 0;
    margin-bottom: rem(30px);
  }

  &--border-top_left {
    border-top-left-radius: 100%;
  }

  &--border-top_right {
    border-top-right-radius: 100%;
  }

  &--border-bottom_left {
    border-bottom-left-radius: 100%;
  }

  &--border-bottom_right {
    border-bottom-right-radius: 100%;
  }

  @include bp(small) {
    .b-team-members__item:nth-of-type(even) & {
      order: 2;
      margin-left: rem(40px);
    }
  }

  .b-team-members__item:nth-of-type(odd) & {
    margin-right: rem(40px);

    @include bp-down(small) {
      margin: 0;
      margin-bottom: rem(30px);
    }
  }
}

.b-team-members__img {
  display: block;
  width: 100%;
  height: auto;
}

.b-team-members__member-name {
  font-size: rem(16px);
  font-weight: 600;
  color: $color-7;
  margin: 0;
}

.b-team-members__member-job {
  color: $color-7;
}

.b-team-members__member-text {
  margin-top: rem(18px);
}
