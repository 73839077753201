/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();

  margin-bottom: 2em;
}

.c-post__banner {
  width: 100%;
  display: block;
  height: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 35%;
  border-bottom-right-radius: rem(80px);

  @include bp(medium) {
    padding-bottom: 28%;
    border-bottom-right-radius: rem(220px);
  }
}

.c-post__heading-wrapper {
  margin-top: rem(80px);
  margin-bottom: rem(40px);
  padding: rem(20px) rem(30px) 0;
}

.c-post__title {
  color: $color-2;
  line-height: 1.3;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-post__pagination {
  padding: rem(20px) rem(30px);
}

.c-post__pagination-heading {
  font-size: rem(25px);
  font-weight: 500;
  color: $color-7;
  line-height: 1.4;
}

.c-post__pagination-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: rem(40px) auto;
}

.c-post__pagination-item {
  margin-top: rem(40px);

  @include bp(medium) {
    width: calc(50% - rem(24px));
  }
}
