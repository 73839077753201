/* stylelint-disable */

@import 'range-slider-element/dist/styles';

.thumb {
  background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23FCF8F5'/%3E%3Ccircle cx='15' cy='15' r='14.5' stroke='%23272824' stroke-opacity='0.3'/%3E%3Ccircle cx='15' cy='15' r='6' fill='%23272824' fill-opacity='0.3'/%3E%3C/svg%3E%0A"); /* Green background */
  border: 0;
}

range-slider.touch-active .thumb-wrapper .thumb {
  transform: scale(1.2)!important;
}

range-slider {
  --thumb-size: 30px;

  &:after {
    content: '';
    height: 1px;
    background: $color-text;
    left: 0;
    right: 0;
    top: calc(50% - 1px);
    transform: tranlsateY(-50%);
    position: absolute;
    z-index: 0;
  }

  &:before {
    z-index: 1;
    height: 7px;
    background: linear-gradient($color-1, $color-1) 0/var(--value-percent, 0) 100% no-repeat transparent;
  }

  .thumb-wrapper {
    z-index: 1;
  }

  &:focus {
    .thumb {
      box-shadow: 0 0 0 0.3em rgba($color-1, 20%);
    }
  }
}
