.c-tease__link {
  text-decoration: none;
}

.c-tease__image {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  display: block;
  overflow: hidden;
  border-bottom-right-radius: rem(6px);
  border-bottom-left-radius: rem(60px);
  border-top-left-radius: rem(6px);
  border-top-right-radius: rem(60px);
  background-repeat: no-repeat;
  background-size: cover;
}

.c-tease__date {
  display: block;
  margin-top: rem(40px);
}

.c-tease__heading {
  margin-top: rem(10px);
  font-size: rem(25px);
  font-family: $font-sans-serif;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
}

.c-tease__desc {
  margin-top: rem(30px);
}
