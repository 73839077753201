/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background: $color-bg-header;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  height: rem(120px);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}

.c-header__placeholder {
  height: rem(120px);
  width: 100%;
  display: block;
}

.c-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto rem(30px);

  @include bp(large) {
    margin: auto rem(120px);
  }
}

.c-header__logo {
  width: 100%;
  max-width: rem(258px);
  height: auto;
  z-index: 3;
  margin-right: rem(12px);
}

.c-header__hamburger {
  @include btnReset;

  cursor: pointer;

  @include bp(1232px) {
    display: none;
  }
}

.svg-close {
  display: none;

  .show-nav & {
    display: block;
  }
}

.svg-hamburger {
  display: block;

  .show-nav & {
    display: none;
  }
}

.c-header__nav {
  margin-left: auto;
  z-index: 5;

  @include bp(large) {
    display: block;
  }
}
