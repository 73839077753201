.b-form-embed {
  margin: rem(60px) 0;
  padding: rem(20px) rem(30px);
}

.b-form-embed__wrapper {
  @include bp(medium) {
    display: flex;
  }
}

.b-form-embed__aside {
  @include bp(medium) {
    width: 30%;
    padding-right: 2%;
  }
}

.b-form-embed__heading {
  font-size: rem(25px);
  font-weight: 500;
  color: $color-7;
  text-transform: uppercase;
}

.b-form-embed__image-wrapper {
  margin-top: rem(16px);
  display: block;
  overflow: hidden;
  border-radius: rem(10px);
  border-top-right-radius: 50%;
}

.b-form-embed__form {
  @include bp(medium) {
    padding-left: 4%;
    width: 70%;
  }
}

.b-form-embed__form-text {
  display: block;
  margin-top: rem(70px);
}

.b-form-embed__form-btn-wrapper {
  position: relative;
  margin-top: rem(20px);
  display: flex;
  justify-content: flex-end;
}
