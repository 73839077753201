/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();

  margin: 0 auto;

  @include bp(large) {
    width: 100%;
    max-width: rem(1165px);
  }
}

.o-wrapper__outer {
  padding: 0 rem(30px);
}
