.c-search__form {
  position: relative;
  width: 100%;
  max-width: rem(564px);

  .svg-search {
    width: 25px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.c-search__input {
  background: none;
  border: none;
  width: 100%;
  font-size: rem(16px);
  color: $color-text;
  border-bottom: 1px solid $color-7;
  padding-bottom: rem(10px);
  padding-right: 28px;

  &::placeholder {
    color: $color-8;
  }
}
