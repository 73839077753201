.b-services-carousel {
  display: block;
  margin-top: rem(70px);
  position: relative;
  padding: 0 rem(30px) rem(20px);
}

.b-services-carousel__heading {
  font-size: rem(25px);
  font-weight: 500;
  color: $color-7;
  text-transform: uppercase;
  max-width: rem(285px);
  font-family: $font-sans-serif;

  @include bp(medium) {
    margin: 0;
  }

  &--big {
    font-size: rem(50px);
    font-weight: 700;
    line-height: 1.1;
    font-family: $font-serif;
    text-transform: none;
    color: $color-2;

    @include bp(medium) {
      max-width: 60%;
    }
  }
}

.b-services-carousel__item {
  text-align: center;
}

.b-services-carousel__carousel-outer {
  margin-top: rem(80px);
}

.b-services-carousel__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: $color-white;
  border: 1px solid $color-4;
  border-radius: 50%;
  width: rem(55px);
  height: rem(55px);
}

.b-services-carousel__item-img {
  max-width: rem(30px);
  width: 100%;
  height: auto;
  max-height: rem(30px);
}

.b-services-carousel__item-heading {
  font-size: rem(16px);
  font-weight: 500;
  margin-top: rem(30px);
  text-transform: uppercase;
  color: $color-2;
}

.b-services-carousel__item-content {
  line-height: 1.7;
}
