$bp-banner: 1180px;

.b-banner {
  margin: rem(50px) 0;
  padding: rem(20px) rem(30px);
  overflow-x: hidden;
}

.b-banner__wrapper {
  @include bp(medium) {
    display: flex;
    align-items: center;
  }
}

.svg-banner-elipse,
.svg-banner-elipse-top {
  .b-banner--has-elipse & {
    display: block !important;
  }
}

.b-banner__content {
  @include bp(medium) {
    width: 80%;
  }

  .b-banner--has-image & {
    @include bp(medium) {
      width: 50%;
    }
  }

  .b-banner--home & {
    @include bp(medium) {
      padding-right: 7%;
    }

    @include bp($bp-banner) {
      width: 45%;
      padding-right: 2%;
    }
  }

  .b-banner--page & {
    @include bp(medium) {
      padding-right: 6%;
    }
  }
}

.b-banner__image {
  @include bp-down(medium) {
    margin-top: rem(50px);
  }

  @include bp(medium) {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }

  .b-banner--home & {
    @include bp($bp-banner) {
      width: 55%;
    }
  }

  .b-banner--page &,
  .b-banner--home & {
    @include bp-down(medium) {
      margin-left: rem(30px);
      margin-right: rem(30px);
    }
  }
}

.b-banner__heading {
  font-size: rem(50px);
  line-height: 1.2;
  color: $color-2;

  .b-banner--home & {
    @include bp(medium) {
      font-size: rem(60px);
    }

    color: $color-1;
    line-height: 1.1;

    @include bp($bp-banner) {
      font-size: rem(100px);
      line-height: 1;
    }
  }
}

.b-banner__above-heading {
  display: inline-block;
  margin-bottom: rem(12px);
  text-transform: uppercase;
}

.b-banner__description {
  display: inline-block;
  margin-top: rem(15px);
  line-height: 1.7;

  @include bp($bp-banner) {
    margin-top: rem(25px);
  }

  .b-banner--home & {
    @include bp($bp-banner) {
      margin-top: rem(25px);
      font-size: rem(20px);
    }
  }
}

.b-banner__link {
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: rem(1px);
  color: $color-1;
  margin-top: rem(15px);
}

.b-banner__image-outer {
  position: relative;
  display: inline-flex;
  align-items: center;

  .b-banner--home & {
    display: inline-flex;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      left: rem(-15px);
      top: rem(-15px);
      width: calc(100% + rem(30px));
      height: calc(100% + rem(30px));
      border: 1px solid $color-4;
      border-top-left-radius: 50%;
      border-top-right-radius: 50%;
      border-bottom-left-radius: rem(20px);
      border-bottom-right-radius: rem(20px);
      background: $color-white;
      z-index: 1;
    }

    .svg-banner-elipse,
    .svg-banner-elipse-top {
      display: none;
      position: absolute;
      height: auto;
      bottom: 3%;
    }

    .svg-banner-elipse {
      left: -10%;
      width: 120%;
      z-index: 0;
    }

    .svg-banner-elipse-top {
      width: 102%;
      z-index: 3;
    }
  }

  .b-banner--page & {
    .svg-banner-elipse2,
    .svg-banner-elipse2-top {
      display: block;
      position: absolute;
      height: auto;
    }

    .svg-banner-elipse2 {
      left: -10%;
      width: 120%;
      z-index: 0;
      bottom: 11.5%;
    }

    .svg-banner-elipse2-top {
      width: 100%;
      z-index: 3;
      bottom: 3%;
    }
  }
}

.b-banner__image-inner {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: rem(20px);
  border-bottom-right-radius: rem(20px);
  z-index: 1;

  .b-banner--page & {
    border-top-left-radius: rem(20px);
    border-top-right-radius: 35%;
    border-bottom-left-radius: 35%;
    border-bottom-right-radius: rem(20px);
  }
}
