$spacer: 16;

.u-pb-4 {
  padding-bottom: rem($spacer * 1.5);
}

.u-pb-5 {
  padding-bottom: rem($spacer * 1.75);
}

.u-pb-6 {
  padding-bottom: rem($spacer * 2);
}
