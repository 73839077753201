.b-heading-text {
  display: block;
  position: relative;
  margin: rem(50px) 0;
  padding: rem(20px) rem(30px);
}

.b-heading-text__wrapper {
  @include bp(medium) {
    display: flex;
  }
}

.b-heading-text__heading {
  margin-bottom: rem(50px);

  @include bp(medium) {
    width: 50%;
    padding-right: rem(60px);
  }
}

.b-heading-text__content {
  line-height: rem(26px);

  @include bp(medium) {
    width: 50%;
    margin-top: rem(160px);
  }
}
