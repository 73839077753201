.c-slider {
  .c-calculator__form & {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: rem(10px);
  }
}

.c-slider__wrapper {
  display: flex;
  margin-bottom: rem(22px);
}

.c-slider__value-wrapper {
  margin-left: auto;
}

.c-slider__label,
.c-slider__value {
  font-size: rem(16px);
  color: $color-black-theme;
}

.c-slider__input {
  appearance: none; /* Override default CSS styles */
  width: 100%; /* Full-width */
  height: 1px; /* Specified height */
  background: $color-text; /* Grey background */
  outline: none; /* Remove outline */
  transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;

  &::-moz-range-progress,
  &::-webkit-slider-runnable-track {
    background: $color-1;
    height: rem(7px);
  }

  &::-moz-range-thumb {
    width: 30px; /* Set a specific slider handle width */
    height: 30px; /* Slider handle height */
    background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23FCF8F5'/%3E%3Ccircle cx='15' cy='15' r='14.5' stroke='%23272824' stroke-opacity='0.3'/%3E%3Ccircle cx='15' cy='15' r='6' fill='%23272824' fill-opacity='0.3'/%3E%3C/svg%3E%0A"); /* Green background */
    border: 0;
    cursor: pointer; /* Cursor on hover */
  }

  &::-webkit-slider-thumb {
    appearance: none; /* Override default look */
    width: 30px; /* Set a specific slider handle width */
    height: 30px; /* Slider handle height */
    background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23FCF8F5'/%3E%3Ccircle cx='15' cy='15' r='14.5' stroke='%23272824' stroke-opacity='0.3'/%3E%3Ccircle cx='15' cy='15' r='6' fill='%23272824' fill-opacity='0.3'/%3E%3C/svg%3E%0A"); /* Green background */
    border: 0;
    cursor: pointer; /* Cursor on hover */
  }
}
