@charset "UTF-8";
/*
  Project: Carter Cook
  Author: GoodieWebsite.com
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}
blockquote p:first-child::before {
  content: open-quote;
}
blockquote p:last-child::after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1 {
  font-family: "Playfair Display", serif;
  font-size: 3.125rem;
}

h2 {
  font-size: 3.125rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
html {
  background: #fdfbf9;
  color: #93928f;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.5;
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-size: 1rem;
  /* 16px */
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic;
  /* [2] */
  max-width: 100%;
  /* [1] */
  vertical-align: middle;
  /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #a5aa94;
  text-decoration: underline;
}
a:hover {
  color: #999;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%;
  /* [1] */
}

/* stylelint-disable */
range-slider {
  --element-height:24px;
  --track-height:3px;
  --thumb-size:16px;
  position: relative;
  display: flex;
  align-items: center;
  height: var(--element-height);
  width: 100%;
  min-width: 130px;
  margin: 2px;
  overflow: visible;
  cursor: pointer;
  touch-action: none;
}

range-slider:focus {
  outline: 0;
}

range-slider[disabled] {
  filter: grayscale(1);
  opacity: 0.8;
}

range-slider:before {
  content: "";
  display: block;
  width: 100%;
  height: var(--track-height);
  border-radius: calc(var(--track-height) / 2);
  background: linear-gradient(#6221ea, #6221ea) 0/var(--value-percent, 0) 100% no-repeat #c6afe5;
}

range-slider:focus .thumb {
  box-shadow: 0 0 0 0.3em rgba(98, 33, 234, 0.2);
}

range-slider.touch-active .thumb-wrapper .thumb {
  box-shadow: none;
  transform: scale(1.5);
}

.thumb {
  background: #6221ea;
  border-radius: 50%;
  width: var(--thumb-size);
  height: var(--thumb-size);
  position: absolute;
  bottom: calc(var(--element-height) / 2 - var(--thumb-size) / 2);
  left: var(--value-percent, 0);
  margin-left: calc(var(--thumb-size) / 2 * -1);
  transition: transform 0.2s ease;
  will-change: transform;
  pointer-events: none;
}

.thumb-wrapper {
  position: absolute;
  left: calc(var(--thumb-size) / 2);
  right: calc(var(--thumb-size) / 2);
  bottom: 0;
  height: 0;
  overflow: visible;
}

/*# sourceMappingURL=/styles.css.map */
.thumb {
  background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23FCF8F5'/%3E%3Ccircle cx='15' cy='15' r='14.5' stroke='%23272824' stroke-opacity='0.3'/%3E%3Ccircle cx='15' cy='15' r='6' fill='%23272824' fill-opacity='0.3'/%3E%3C/svg%3E%0A");
  /* Green background */
  border: 0;
}

range-slider.touch-active .thumb-wrapper .thumb {
  transform: scale(1.2) !important;
}

range-slider {
  --thumb-size: 30px;
}
range-slider:after {
  content: "";
  height: 1px;
  background: #93928f;
  left: 0;
  right: 0;
  top: calc(50% - 1px);
  transform: tranlsateY(-50%);
  position: absolute;
  z-index: 0;
}
range-slider:before {
  z-index: 1;
  height: 7px;
  background: linear-gradient(#ef9d87, #ef9d87) 0/var(--value-percent, 0) 100% no-repeat transparent;
}
range-slider .thumb-wrapper {
  z-index: 1;
}
range-slider:focus .thumb {
  box-shadow: 0 0 0 0.3em rgba(239, 157, 135, 0.2);
}

/* stylelint-disable */
#sbi_load {
  display: none;
}

#sb_instagram {
  margin-top: 3.75rem !important;
  padding: 0 !important;
}
#sb_instagram #sbi_images {
  padding: 0 !important;
  margin: 0 auto !important;
  float: none !important;
}
#sb_instagram #sbi_images .sbi_item {
  display: none !important;
}
#sb_instagram #sbi_images .sbi_item:nth-child(-n+4) {
  display: inline-block !important;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(1turn);
  }
}
.splide__track--draggable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.splide__track--fade > .splide__list {
  display: block;
}

.splide__track--fade > .splide__list > .splide__slide {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  position: relative;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none;
}

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  position: relative;
  visibility: hidden;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  position: relative;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.splide__sr {
  clip: rect(0 0 0 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.splide__arrow {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  text-align: inherit;
  /* iOS fix */
  border-radius: 0;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  cursor: pointer;
}
.splide__arrow--prev .svg-arrow {
  transform-origin: 50% 50%;
  transform: rotate(180deg);
}

.b-testimonials .splide__arrows {
  text-align: center;
}
@media (min-width: 740px) {
  .b-testimonials .splide__arrows {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.b-services-carousel .splide__arrows, .b-latest-events .splide__arrows {
  margin-top: 1.25rem;
  text-align: center;
}

.o-heading {
  font-weight: 700;
  line-height: 1.1;
  font-family: "Playfair Display", serif;
  color: #a5aa94;
}

.o-heading__text-above {
  display: inline-block;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: #91918e;
  font-size: 1rem;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}
.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

.o-pagination {
  text-align: center;
  margin: 1.5rem;
}
.o-pagination .current {
  font-weight: 700;
}
.o-pagination .nav-links > * {
  display: inline;
  margin: 0.25rem;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

.o-text__search-not-found {
  display: block;
  margin: 2.5rem auto;
  font-size: 1.5rem;
}

.o-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2.5rem auto;
}

.o-tiles__item {
  margin-top: 2.5rem;
}
@media (min-width: 768px) {
  .o-tiles__item {
    width: calc(50% - 1.5rem);
  }
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper, #sb_instagram #sbi_images {
  margin: 0 auto;
}
.o-wrapper::after, #sb_instagram #sbi_images::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}
@media (min-width: 1024px) {
  .o-wrapper, #sb_instagram #sbi_images {
    width: 100%;
    max-width: 72.8125rem;
  }
}

.o-wrapper__outer {
  padding: 0 1.875rem;
}

.b-banner {
  margin: 3.125rem 0;
  padding: 1.25rem 1.875rem;
  overflow-x: hidden;
}

@media (min-width: 768px) {
  .b-banner__wrapper {
    display: flex;
    align-items: center;
  }
}

.b-banner--has-elipse .svg-banner-elipse,
.b-banner--has-elipse .svg-banner-elipse-top {
  display: block !important;
}

@media (min-width: 768px) {
  .b-banner__content {
    width: 80%;
  }
}
@media (min-width: 768px) {
  .b-banner--has-image .b-banner__content {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .b-banner--home .b-banner__content {
    padding-right: 7%;
  }
}
@media (min-width: 1180px) {
  .b-banner--home .b-banner__content {
    width: 45%;
    padding-right: 2%;
  }
}
@media (min-width: 768px) {
  .b-banner--page .b-banner__content {
    padding-right: 6%;
  }
}

@media (max-width: 767.98px) {
  .b-banner__image {
    margin-top: 3.125rem;
  }
}
@media (min-width: 768px) {
  .b-banner__image {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 1180px) {
  .b-banner--home .b-banner__image {
    width: 55%;
  }
}
@media (max-width: 767.98px) {
  .b-banner--page .b-banner__image, .b-banner--home .b-banner__image {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }
}

.b-banner__heading {
  font-size: 3.125rem;
  line-height: 1.2;
  color: #a5aa94;
}
.b-banner--home .b-banner__heading {
  color: #ef9d87;
  line-height: 1.1;
}
@media (min-width: 768px) {
  .b-banner--home .b-banner__heading {
    font-size: 3.75rem;
  }
}
@media (min-width: 1180px) {
  .b-banner--home .b-banner__heading {
    font-size: 6.25rem;
    line-height: 1;
  }
}

.b-banner__above-heading {
  display: inline-block;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
}

.b-banner__description {
  display: inline-block;
  margin-top: 0.9375rem;
  line-height: 1.7;
}
@media (min-width: 1180px) {
  .b-banner__description {
    margin-top: 1.5625rem;
  }
}
@media (min-width: 1180px) {
  .b-banner--home .b-banner__description {
    margin-top: 1.5625rem;
    font-size: 1.25rem;
  }
}

.b-banner__link {
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  color: #ef9d87;
  margin-top: 0.9375rem;
}

.b-banner__image-outer {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.b-banner--home .b-banner__image-outer {
  display: inline-flex;
  align-items: center;
}
.b-banner--home .b-banner__image-outer::before {
  content: "";
  position: absolute;
  left: -0.9375rem;
  top: -0.9375rem;
  width: calc(100% + 1.875rem);
  height: calc(100% + 1.875rem);
  border: 1px solid #f1cdb7;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  background: #fff;
  z-index: 1;
}
.b-banner--home .b-banner__image-outer .svg-banner-elipse,
.b-banner--home .b-banner__image-outer .svg-banner-elipse-top {
  display: none;
  position: absolute;
  height: auto;
  bottom: 3%;
}
.b-banner--home .b-banner__image-outer .svg-banner-elipse {
  left: -10%;
  width: 120%;
  z-index: 0;
}
.b-banner--home .b-banner__image-outer .svg-banner-elipse-top {
  width: 102%;
  z-index: 3;
}
.b-banner--page .b-banner__image-outer .svg-banner-elipse2,
.b-banner--page .b-banner__image-outer .svg-banner-elipse2-top {
  display: block;
  position: absolute;
  height: auto;
}
.b-banner--page .b-banner__image-outer .svg-banner-elipse2 {
  left: -10%;
  width: 120%;
  z-index: 0;
  bottom: 11.5%;
}
.b-banner--page .b-banner__image-outer .svg-banner-elipse2-top {
  width: 100%;
  z-index: 3;
  bottom: 3%;
}

.b-banner__image-inner {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  z-index: 1;
}
.b-banner--page .b-banner__image-inner {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 35%;
  border-bottom-left-radius: 35%;
  border-bottom-right-radius: 1.25rem;
}

.b-calculator {
  margin: 3.125rem 0;
  padding: 1.25rem 1.875rem;
}

.b-faq {
  margin: 3.125rem 0;
  padding: 1.25rem 1.875rem;
}

.b-faq__items {
  margin-top: 3.125rem;
}
@media (min-width: 768px) {
  .b-faq__items.b-faq__items-columns {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    display: flex;
  }
}

@media (min-width: 768px) {
  .b-faq__col {
    width: 50%;
  }
}

.b-faq__item-trigger {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  text-align: inherit;
  /* iOS fix */
  border-radius: 0;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  width: 100%;
  cursor: pointer;
}

.b-faq__item-heading {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #91918e;
  display: flex;
  align-items: center;
}
.b-faq__item-heading span {
  margin-right: 1rem;
}
.b-faq__item-heading > svg {
  margin-left: auto;
  width: 1.25rem;
}
.b-faq__item-trigger[aria-expanded=true] .b-faq__item-heading > svg {
  transform: rotate(90deg);
}

.b-faq__item-content {
  transition: all 0.3s ease-in-out;
}
.b-faq__item-content[aria-hidden=true] {
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.b-faq__item-content[aria-hidden=false] {
  height: auto;
  opacity: 1;
}

.b-faq__item {
  border-bottom: 1px solid #91918e;
  margin-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .b-faq__item {
    width: calc(50% - 2.5rem);
    margin: 1.25rem;
  }
  .b-faq__items-columns .b-faq__item {
    width: auto;
  }
}

.b-form-embed {
  margin: 3.75rem 0;
  padding: 1.25rem 1.875rem;
}

@media (min-width: 768px) {
  .b-form-embed__wrapper {
    display: flex;
  }
}

@media (min-width: 768px) {
  .b-form-embed__aside {
    width: 30%;
    padding-right: 2%;
  }
}

.b-form-embed__heading {
  font-size: 1.5625rem;
  font-weight: 500;
  color: #c4c9b2;
  text-transform: uppercase;
}

.b-form-embed__image-wrapper {
  margin-top: 1rem;
  display: block;
  overflow: hidden;
  border-radius: 0.625rem;
  border-top-right-radius: 50%;
}

@media (min-width: 768px) {
  .b-form-embed__form {
    padding-left: 4%;
    width: 70%;
  }
}

.b-form-embed__form-text {
  display: block;
  margin-top: 4.375rem;
}

.b-form-embed__form-btn-wrapper {
  position: relative;
  margin-top: 1.25rem;
  display: flex;
  justify-content: flex-end;
}

.b-heading-text {
  display: block;
  position: relative;
  margin: 3.125rem 0;
  padding: 1.25rem 1.875rem;
}

@media (min-width: 768px) {
  .b-heading-text__wrapper {
    display: flex;
  }
}

.b-heading-text__heading {
  margin-bottom: 3.125rem;
}
@media (min-width: 768px) {
  .b-heading-text__heading {
    width: 50%;
    padding-right: 3.75rem;
  }
}

.b-heading-text__content {
  line-height: 1.625rem;
}
@media (min-width: 768px) {
  .b-heading-text__content {
    width: 50%;
    margin-top: 10rem;
  }
}

.b-image {
  display: block;
  margin: 1.875rem 0;
}

.b-image__wrapper {
  padding: 1.25rem 1.875rem;
}

.b-image__image {
  overflow: hidden;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 3.75rem;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 3.75rem;
}

.b-latest-events {
  margin: 3.125rem 0;
  padding: 1.25rem 1.875rem;
}

.b-latest-events__items {
  display: block;
}

.b-latest-events__item {
  display: block;
  text-decoration: none;
  margin-top: 3.75rem;
}

.b-latest-events__item-image {
  display: block;
  margin-bottom: 2rem;
}

.b-latest-events__item-img {
  width: 100%;
  height: auto;
}

.b-latest-events__item-date {
  display: block;
  margin-top: 1.75rem;
}

.b-latest-events__item-heading {
  font-size: 1.5625rem;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 0.5rem;
  line-height: 1.4;
}

.b-services-carousel {
  display: block;
  margin-top: 4.375rem;
  position: relative;
  padding: 0 1.875rem 1.25rem;
}

.b-services-carousel__heading {
  font-size: 1.5625rem;
  font-weight: 500;
  color: #c4c9b2;
  text-transform: uppercase;
  max-width: 17.8125rem;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
}
@media (min-width: 768px) {
  .b-services-carousel__heading {
    margin: 0;
  }
}
.b-services-carousel__heading--big {
  font-size: 3.125rem;
  font-weight: 700;
  line-height: 1.1;
  font-family: "Playfair Display", serif;
  text-transform: none;
  color: #a5aa94;
}
@media (min-width: 768px) {
  .b-services-carousel__heading--big {
    max-width: 60%;
  }
}

.b-services-carousel__item {
  text-align: center;
}

.b-services-carousel__carousel-outer {
  margin-top: 5rem;
}

.b-services-carousel__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #f1cdb7;
  border-radius: 50%;
  width: 3.4375rem;
  height: 3.4375rem;
}

.b-services-carousel__item-img {
  max-width: 1.875rem;
  width: 100%;
  height: auto;
  max-height: 1.875rem;
}

.b-services-carousel__item-heading {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1.875rem;
  text-transform: uppercase;
  color: #a5aa94;
}

.b-services-carousel__item-content {
  line-height: 1.7;
}

.b-team-members {
  margin: 3.125rem 0;
  padding: 1.25rem 1.875rem;
}

.b-team-members__heading {
  font-size: 1.5625rem;
  font-weight: 500;
  color: #c4c9b2;
  text-transform: uppercase;
  max-width: 17.8125rem;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
}
@media (min-width: 768px) {
  .b-team-members__heading {
    margin: 0;
  }
}

.b-team-members__items {
  margin-top: 7.5rem;
  display: block;
}

.b-team-members__item {
  margin: 5rem 0;
}
@media (min-width: 480px) {
  .b-team-members__item {
    display: flex;
    align-items: flex-start;
  }
}
@media (min-width: 768px) {
  .b-team-members__item {
    width: 60%;
  }
  .b-team-members__item:nth-of-type(even) {
    margin: -1.25rem 0 -1.25rem auto;
  }
}

.b-team-members__image {
  display: block;
  flex: 0 0 100%;
  width: 100%;
  max-width: 14.375rem;
  overflow: hidden;
  border-radius: 1rem;
}
@media (max-width: 479.98px) {
  .b-team-members__image {
    margin: 0;
    margin-bottom: 1.875rem;
  }
}
.b-team-members__image--border-top_left {
  border-top-left-radius: 100%;
}
.b-team-members__image--border-top_right {
  border-top-right-radius: 100%;
}
.b-team-members__image--border-bottom_left {
  border-bottom-left-radius: 100%;
}
.b-team-members__image--border-bottom_right {
  border-bottom-right-radius: 100%;
}
@media (min-width: 480px) {
  .b-team-members__item:nth-of-type(even) .b-team-members__image {
    order: 2;
    margin-left: 2.5rem;
  }
}
.b-team-members__item:nth-of-type(odd) .b-team-members__image {
  margin-right: 2.5rem;
}
@media (max-width: 479.98px) {
  .b-team-members__item:nth-of-type(odd) .b-team-members__image {
    margin: 0;
    margin-bottom: 1.875rem;
  }
}

.b-team-members__img {
  display: block;
  width: 100%;
  height: auto;
}

.b-team-members__member-name {
  font-size: 1rem;
  font-weight: 600;
  color: #c4c9b2;
  margin: 0;
}

.b-team-members__member-job {
  color: #c4c9b2;
}

.b-team-members__member-text {
  margin-top: 1.125rem;
}

.b-testimonials {
  display: block;
  margin-top: 4.375rem;
  position: relative;
  padding: 0 1.875rem 1.25rem;
  overflow: hidden;
}

.b-testimonials__heading {
  font-size: 1.5625rem;
  font-weight: 500;
  color: #c4c9b2;
  text-transform: uppercase;
  max-width: 17.8125rem;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
}
@media (min-width: 768px) {
  .b-testimonials__heading {
    margin: 0;
  }
}

.b-testimonials__carousel-outer {
  margin-top: 3.125rem;
}
@media (min-width: 740px) {
  .b-testimonials__carousel-outer {
    margin-top: -1.875rem;
  }
}

.b-testimonials__carousel-item {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  padding: 0 2rem;
}
.b-testimonials__carousel-item.is-prev {
  opacity: 0.3;
}
@media (min-width: 740px) {
  .b-testimonials__carousel-item.is-active, .b-testimonials__carousel-item.is-prev {
    margin-top: 5.625rem;
  }
}

.b-testimonials__item-image {
  display: inline-block;
  overflow: hidden;
  margin-right: 0.75rem;
  border-top-right-radius: 65%;
  border-bottom-left-radius: 65%;
  border-top-left-radius: 35%;
  border-bottom-right-radius: 35%;
}

.b-testimonials__author-img {
  width: 100%;
  max-width: 4.5625rem;
  height: auto;
}

.b-testimonials__item-author {
  display: flex;
  align-items: center;
}

.b-testimonials__item-heading {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #c4c9b2;
}

.b-testimonials__item-job-description {
  color: #c4c9b2;
}

.b-testimonials__item-content {
  margin-top: 1.5rem;
}

.b-testimonials__carousel-inner {
  padding-bottom: 2.5rem;
}

.b-testimonials__carousel-loop-fix {
  margin: 0 -2rem;
}

.b-wysiwyg {
  padding: 1.25rem 1.875rem;
}
.b-wysiwyg h2,
.b-wysiwyg h3,
.b-wysiwyg h4,
.b-wysiwyg h5,
.b-wysiwyg h6 {
  font-weight: 500;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  color: #a5aa94;
  margin-top: 0;
  margin-bottom: 2rem;
}
.b-wysiwyg h2 {
  font-size: 1.5625rem;
}
.b-wysiwyg p {
  margin-bottom: 2rem;
}
.b-wysiwyg blockquote {
  font-size: 1.5625rem;
  font-weight: 500;
  font-style: normal;
  color: #a5aa94;
  margin: 3.75rem 0 3.75rem 30%;
}

.c-404__wrapper {
  padding: 1.5rem;
}

.c-404__title {
  font-size: 5rem;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  transition: all 300ms ease-in-out;
  border-radius: 50%;
  padding: 0.25rem 0.875rem;
  background: #fff;
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  color: #ef9d87;
  font-weight: 600;
  border: 1px solid #ef9d87;
}
.c-btn--primary, .c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  text-decoration: none;
  /* [4] */
}
.c-btn--primary:hover, .c-btn--primary:focus {
  background-color: #ef9d87;
  color: #fff;
}

/* stylelint-disable */
.c-calculator {
  border-radius: 1rem;
  background-color: #fff;
  margin-top: 3.75rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);
  padding: 1.25rem;
}
@media (min-width: 768px) {
  .c-calculator {
    padding: 2.5rem;
  }
}

@media (min-width: 768px) {
  .c-calculator__form {
    display: flex;
  }
}

.c-calculator__inputs {
  position: relative;
}
@media (min-width: 768px) {
  .c-calculator__inputs {
    width: 55%;
    padding-right: 2.4375rem;
  }
  .c-calculator__inputs::after {
    content: "";
    width: 1px;
    height: calc(100% + 5rem);
    background: #efe3d3;
    display: block;
    top: -2.5rem;
    right: 0;
    position: absolute;
  }
}

@media (min-width: 768px) {
  .c-calculator__results {
    width: 45%;
    padding-left: 2.375rem;
  }
}

.c-calculator__submit {
  display: flex;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .c-calculator__submit {
    margin-top: 0.875rem;
  }
}

.c-calculator__btn {
  margin-left: auto;
}

.c-calculator__price-box {
  display: none;
}
.c-calculator__price-box.show {
  display: block;
}

.c-calculator__price-box-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.c-calculator__price-box__box {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  text-align: inherit;
  /* iOS fix */
  border-radius: 0;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  display: inline-block;
  margin: 0 0.25rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 2.5rem;
  border-bottom-left-radius: 2.5rem;
  border-bottom-right-radius: 0.625rem;
  padding: 0.625rem 1.875rem 1.125rem;
  margin-bottom: 0.5rem;
  text-align: center;
  background: #fff;
  border: 1px solid rgba(241, 205, 183, 0.2);
}
.c-calculator__price-box__box.clickable {
  cursor: pointer;
}
.c-calculator__price-box__box.active {
  background: rgba(241, 205, 183, 0.2);
}
.c-calculator__price-box__box.force-hide {
  display: none;
}
@media (min-width: 480px) {
  .c-calculator__price-box__box {
    width: calc(50% - 0.5rem);
  }
}

.c-calculator__price-box__text {
  font-size: 0.75rem;
}

.c-calculator__price-box__price {
  font-size: 2.5rem;
  font-weight: 600;
  color: #ef9d87;
  margin-bottom: 0.5rem;
}

.c-calculator__price-box__price-time {
  color: #93928f;
  font-size: 0.75rem;
}

.c-calculator__tier-benefits__list {
  padding: 0;
  margin: 0;
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .c-calculator__tier-benefits__list {
    display: flex;
  }
}

@media (min-width: 768px) {
  .c-calculator__tier-benefits__list-col {
    width: 50%;
  }
}

.c-calculator__tier-benefits__list-item {
  font-size: 0.75rem;
  list-style-type: none;
  display: flex;
  position: relative;
  padding-left: 1.125rem;
}
.c-calculator__tier-benefits__list-item svg {
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  left: 0;
  top: 0.125rem;
}

.c-calculator__additional {
  display: flex;
}

.c-calculator__additional-text {
  font-size: 0.75rem;
  display: block;
  max-width: 18.75rem;
  text-align: right;
  margin-left: auto;
  margin-top: 1.25rem;
}

/* ==========================================================================
   #COMMENT FORM
   Here we are using default HTML structure and classes,
   to create custom ones use comment_form_defaults filter
   ========================================================================== */
.c-comment-form {
  margin-top: 2em;
}
.c-comment-form label {
  display: block;
}
.c-comment-form textarea {
  max-width: 100%;
}

.comment-reply-title {
  margin-bottom: 0.25em;
}

/* ==========================================================================
   #COMMENT
   ========================================================================== */
.c-comment {
  margin-bottom: 1em;
}

.c-comment__author {
  font-size: 1rem;
  margin-bottom: 0.25em;
}

.c-comment__moderation {
  font-style: italic;
  margin-bottom: 0.5em;
}

.c-footer-nav {
  margin-top: 2.5rem;
}
@media (min-width: 480px) {
  .c-footer-nav:first-child {
    margin-right: 7.5rem;
  }
}

.c-footer-nav__heading {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  color: #a5aa94;
  margin-bottom: 1.75rem;
}

.c-footer-nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 0.625rem;
}

.c-footer-nav__link {
  text-decoration: none;
  font-size: 0.875rem;
  color: #a5aa94;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  background: #fff;
  padding: 5.625rem 0 2.25rem;
}

.c-footer__wrapper {
  margin: auto 1.875rem;
}
@media (min-width: 1024px) {
  .c-footer__wrapper {
    margin: auto 7.5rem;
  }
}

@media (min-width: 1024px) {
  .c-footer__content {
    display: flex;
  }
}

@media (max-width: 479.98px) {
  .c-footer__content-logo {
    text-align: center;
  }
}

.c-footer__content-logo-img {
  width: 100%;
  max-width: 16.125rem;
}

.c-footer__content-links {
  text-align: center;
}
@media (min-width: 480px) {
  .c-footer__content-links {
    display: flex;
  }
}
@media (min-width: 1024px) {
  .c-footer__content-links {
    margin-left: auto;
    width: 40%;
    text-align: left;
  }
}

.c-footer__copyright {
  margin-top: 3rem;
  padding-top: 0.6875rem;
  display: block;
  border-top: 1px solid #ccc;
  font-size: 0.875rem;
  color: #bfbfbe;
}

.c-footer__content-logo-text {
  display: flex;
  align-items: center;
  margin-top: 2.25rem;
  font-size: 1.25rem;
  color: #a5aa94;
}
.c-footer__content-logo-text svg {
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 0.5rem;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  background: #fff;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  height: 7.5rem;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}

.c-header__placeholder {
  height: 7.5rem;
  width: 100%;
  display: block;
}

.c-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto 1.875rem;
}
@media (min-width: 1024px) {
  .c-header__wrapper {
    margin: auto 7.5rem;
  }
}

.c-header__logo {
  width: 100%;
  max-width: 16.125rem;
  height: auto;
  z-index: 3;
  margin-right: 0.75rem;
}

.c-header__hamburger {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  text-align: inherit;
  /* iOS fix */
  border-radius: 0;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  cursor: pointer;
}
@media (min-width: 1232px) {
  .c-header__hamburger {
    display: none;
  }
}

.svg-close {
  display: none;
}
.show-nav .svg-close {
  display: block;
}

.svg-hamburger {
  display: block;
}
.show-nav .svg-hamburger {
  display: none;
}

.c-header__nav {
  margin-left: auto;
  z-index: 5;
}
@media (min-width: 1024px) {
  .c-header__nav {
    display: block;
  }
}

/* ==========================================================================
   #MAIN NAV
   ========================================================================== */
.c-main-nav {
  display: flex;
  align-items: center;
  margin: 0;
}
@media (max-width: 1231.98px) {
  .c-main-nav {
    display: none;
    position: fixed;
    background: #fff;
    top: 7.5rem;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }
  .show-nav .c-main-nav {
    display: flex;
  }
}

.c-main-nav__item {
  font-weight: 400;
  list-style: none;
  padding: 0;
  margin: 0 0.875rem;
}
@media (max-width: 1231.98px) {
  .c-main-nav__item {
    margin-bottom: 0.75rem;
  }
}

.c-main-nav__link {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}

.current-menu-item .c-main-nav__link:not(.c-btn) {
  position: relative;
}
.current-menu-item .c-main-nav__link:not(.c-btn)::after {
  content: "";
  position: absolute;
  bottom: -0.125rem;
  display: block;
  background: #f1cdb7;
  width: 100%;
  height: 1px;
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}
.c-main-nav__item:hover .c-main-nav__dropdown {
  display: flex;
}

/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */
.c-post {
  margin-bottom: 2em;
}
.c-post::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.c-post__banner {
  width: 100%;
  display: block;
  height: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 35%;
  border-bottom-right-radius: 5rem;
}
@media (min-width: 768px) {
  .c-post__banner {
    padding-bottom: 28%;
    border-bottom-right-radius: 13.75rem;
  }
}

.c-post__heading-wrapper {
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  padding: 1.25rem 1.875rem 0;
}

.c-post__title {
  color: #a5aa94;
  line-height: 1.3;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}

.c-post__pagination {
  padding: 1.25rem 1.875rem;
}

.c-post__pagination-heading {
  font-size: 1.5625rem;
  font-weight: 500;
  color: #c4c9b2;
  line-height: 1.4;
}

.c-post__pagination-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2.5rem auto;
}

.c-post__pagination-item {
  margin-top: 2.5rem;
}
@media (min-width: 768px) {
  .c-post__pagination-item {
    width: calc(50% - 1.5rem);
  }
}

.c-quantity__wrapper {
  margin: 1.25rem 0 1.875rem;
}
@media (min-width: 768px) {
  .c-quantity__wrapper {
    display: flex;
  }
}

@media (min-width: 768px) {
  .c-quantity__label {
    padding-right: 3.125rem;
  }
}
@media (min-width: 1024px) {
  .c-quantity__label {
    padding-right: 6.25rem;
  }
}

.c-quantity__input-wrapper {
  display: flex;
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .c-quantity__input-wrapper {
    margin-top: 1rem;
  }
}

.c-quantity__button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  text-align: inherit;
  /* iOS fix */
  border-radius: 0;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 1px solid #93928f;
  background: #fcf9f6;
  font-size: 1.625rem;
  cursor: pointer;
}

.c-quantity__input {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  text-align: inherit;
  /* iOS fix */
  border-radius: 0;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  margin: 0;
  appearance: none;
  appearance: textfield;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
}
.c-quantity__input::-webkit-outer-spin-button, .c-quantity__input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.c-search__form {
  position: relative;
  width: 100%;
  max-width: 35.25rem;
}
.c-search__form .svg-search {
  width: 25px;
  position: absolute;
  top: 0;
  right: 0;
}

.c-search__input {
  background: none;
  border: none;
  width: 100%;
  font-size: 1rem;
  color: #93928f;
  border-bottom: 1px solid #c4c9b2;
  padding-bottom: 0.625rem;
  padding-right: 28px;
}
.c-search__input::placeholder {
  color: #91918e;
}

.c-calculator__form .c-slider {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 0.625rem;
}

.c-slider__wrapper {
  display: flex;
  margin-bottom: 1.375rem;
}

.c-slider__value-wrapper {
  margin-left: auto;
}

.c-slider__label,
.c-slider__value {
  font-size: 1rem;
  color: #93928f;
}

.c-slider__input {
  appearance: none;
  /* Override default CSS styles */
  width: 100%;
  /* Full-width */
  height: 1px;
  /* Specified height */
  background: #93928f;
  /* Grey background */
  outline: none;
  /* Remove outline */
  transition: 0.2s;
  /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}
.c-slider__input::-moz-range-progress, .c-slider__input::-webkit-slider-runnable-track {
  background: #ef9d87;
  height: 0.4375rem;
}
.c-slider__input::-moz-range-thumb {
  width: 30px;
  /* Set a specific slider handle width */
  height: 30px;
  /* Slider handle height */
  background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23FCF8F5'/%3E%3Ccircle cx='15' cy='15' r='14.5' stroke='%23272824' stroke-opacity='0.3'/%3E%3Ccircle cx='15' cy='15' r='6' fill='%23272824' fill-opacity='0.3'/%3E%3C/svg%3E%0A");
  /* Green background */
  border: 0;
  cursor: pointer;
  /* Cursor on hover */
}
.c-slider__input::-webkit-slider-thumb {
  appearance: none;
  /* Override default look */
  width: 30px;
  /* Set a specific slider handle width */
  height: 30px;
  /* Slider handle height */
  background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' fill='%23FCF8F5'/%3E%3Ccircle cx='15' cy='15' r='14.5' stroke='%23272824' stroke-opacity='0.3'/%3E%3Ccircle cx='15' cy='15' r='6' fill='%23272824' fill-opacity='0.3'/%3E%3C/svg%3E%0A");
  /* Green background */
  border: 0;
  cursor: pointer;
  /* Cursor on hover */
}

.c-tease__link {
  text-decoration: none;
}

.c-tease__image {
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  display: block;
  overflow: hidden;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 3.75rem;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 3.75rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-tease__date {
  display: block;
  margin-top: 2.5rem;
}

.c-tease__heading {
  margin-top: 0.625rem;
  font-size: 1.5625rem;
  font-family: "Montserrat", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.3;
  text-transform: uppercase;
}

.c-tease__desc {
  margin-top: 1.875rem;
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.u-pb-4 {
  padding-bottom: 1.5rem;
}

.u-pb-5 {
  padding-bottom: 1.75rem;
}

.u-pb-6 {
  padding-bottom: 2rem;
}