.c-footer-nav {
  margin-top: rem(40px);

  &:first-child {
    @include bp(small) {
      margin-right: rem(120px);
    }
  }
}

.c-footer-nav__heading {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  color: $color-2;
  margin-bottom: rem(28px);
}

.c-footer-nav__list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: rem(10px);
}

.c-footer-nav__link {
  text-decoration: none;
  font-size: rem(14px);
  color: $color-2;
}
