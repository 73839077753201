.c-quantity__wrapper {
  @include bp(medium) {
    display: flex;
  }

  margin: rem(20px) 0 rem(30px);
}

.c-quantity__label {
  @include bp(medium) {
    padding-right: rem(50px);
  }

  @include bp(large) {
    padding-right: rem(100px);
  }
}

.c-quantity__input-wrapper {
  display: flex;
  margin-left: auto;

  @include bp-down(medium) {
    margin-top: rem(16px);
  }
}

.c-quantity__button {
  @include btnReset();

  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(40px);
  height: rem(40px);
  border-radius: 50%;
  border: 1px solid $color-black-theme;
  background: $color-9;
  font-size: rem(26px);
  cursor: pointer;
}

.c-quantity__input {
  @include btnReset();

  margin: 0;
  appearance: none;
  appearance: textfield;
  display: inline-block;
  width: rem(40px);
  height: rem(40px);
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}
