.b-wysiwyg {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    font-family: $font-sans-serif;
    color: $color-2;
    margin-top: 0;
    margin-bottom: rem(32px);
  }

  h2 {
    font-size: rem(25px);
  }

  p {
    margin-bottom: rem(32px);
  }

  blockquote {
    font-size: rem(25px);
    font-weight: 500;
    font-style: normal;
    color: $color-2;
    margin: rem(60px) 0 rem(60px) 30%;
  }

  padding: rem(20px) rem(30px);
}
