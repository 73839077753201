// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: 'Montserrat', -apple-system, system-ui, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif !default;
$font-serif: 'Playfair Display', serif;
$font-size-default: 16px;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 768px,
  large: 1024px,
  xlarge: 1280px,
  full: 1600px,
);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #272824;
$color-very-light-grey: #ccc;
$color-light-grey: #999;
$color-medium-grey: #666;
$color-dark-grey: #333;
$color-black-theme: #93928f;
$color-1: #ef9d87;
$color-2: #a5aa94;
$color-3: #fdfbf9;
$color-4: #f1cdb7;
$color-5: #bfbfbe;
$color-6: #efe3d3;
$color-7: #c4c9b2;
$color-8: #91918e;
$color-9: #fcf9f6;

// Text
$color-bg: $color-white;
$color-text: $color-black-theme;

// Links
$color-link: $color-2;
$color-hover: $color-light-grey;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;
$color-bg-header: $color-white;
$color-bg-footer: $color-white;

$color-html-bg: $color-3;

$color-border-menu: $color-4;

$color-btn--primary: $color-1;
