.b-faq {
  margin: rem(50px) 0;
  padding: rem(20px) rem(30px);
}

.b-faq__items {
  margin-top: rem(50px);

  &.b-faq__items-columns {
    @include bp(medium) {
      margin-left: rem(-20px);
      margin-right: rem(-20px);
      display: flex;
    }
  }
}

.b-faq__col {
  @include bp(medium) {
    width: 50%;
  }
}

.b-faq__item-trigger {
  @include btnReset();

  width: 100%;
  cursor: pointer;
}

.b-faq__item-heading {
  font-size: rem(16px);
  font-weight: 400;
  line-height: 1.5;
  color: $color-8;
  display: flex;
  align-items: center;

  span {
    margin-right: rem(16px);
  }

  > svg {
    margin-left: auto;
    width: rem(20px);

    .b-faq__item-trigger[aria-expanded='true'] & {
      transform: rotate(90deg);
    }
  }
}

.b-faq__item-content {
  transition: all 0.3s ease-in-out;

  &[aria-hidden='true'] {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  &[aria-hidden='false'] {
    height: auto;
    opacity: 1;
  }
}

.b-faq__item {
  border-bottom: 1px solid $color-8;
  margin-bottom: rem(20px);

  @include bp(medium) {
    width: calc(50% - rem(40px));

    .b-faq__items-columns & {
      width: auto;
    }

    margin: rem(20px);
  }
}
