/* stylelint-disable */
#sbi_load {
  display: none;
}

#sb_instagram {
  margin-top: rem(60px) !important;
  padding: 0 !important;

  #sbi_images {
    padding: 0 !important;
    @extend .o-wrapper;
    margin: 0 auto !important;
    float: none !important;

    .sbi_item {
      display: none !important;

      &:nth-child(-n + 4) {
        display: inline-block !important;
      }
    }
  }
}
