/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  align-items: center;
  margin: 0;

  @include bp-down(1232px) {
    display: none;
    position: fixed;
    background: $color-white;
    top: rem(120px);
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    text-align: center;
    flex-direction: column;

    .show-nav & {
      display: flex;
    }
  }
}

.c-main-nav__item {
  font-weight: 400;
  list-style: none;
  padding: 0;
  margin: 0 rem(14px);

  @include bp-down(1232px) {
    margin-bottom: rem(12px);
  }
}

.c-main-nav__link {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}

.current-menu-item .c-main-nav__link:not(.c-btn) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: rem(-2px);
    display: block;
    background: $color-border-menu;
    width: 100%;
    height: 1px;
  }
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;

  .c-main-nav__item:hover & {
    display: flex;
  }
}
