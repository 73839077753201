.b-latest-events {
  margin: rem(50px) 0;
  padding: rem(20px) rem(30px);
}

.b-latest-events__items {
  display: block;
}

.b-latest-events__item {
  display: block;
  text-decoration: none;
  margin-top: rem(60px);
}

.b-latest-events__item-image {
  display: block;
  margin-bottom: rem(32px);
}

.b-latest-events__item-img {
  width: 100%;
  height: auto;
}

.b-latest-events__item-date {
  display: block;
  margin-top: rem(28px);
}

.b-latest-events__item-heading {
  font-size: rem(25px);
  font-family: $font-sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: rem(8px);
  line-height: 1.4;
}
