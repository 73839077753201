@import '@splidejs/splide/dist/css/splide-core.min';

.splide__arrow {
  @include btnReset();

  cursor: pointer;

  &--prev {
    .svg-arrow {
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  }
}

.splide__arrows {
  .b-testimonials & {
    text-align: center;

    @include bp(740px) {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .b-services-carousel &,
  .b-latest-events & {
    margin-top: rem(20px);
    text-align: center;
  }
}
