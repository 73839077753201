.o-pagination {
  text-align: center;
  margin: rem(24px);

  .current {
    font-weight: 700;
  }

  .nav-links {
    > * {
      display: inline;
      margin: rem(4px);
    }
  }
}
